

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

input, textarea {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

:root {
  --small-radius: 8px;
  --large-radius: 24px;
  --purple-outline-color: #5757bc;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {

}



body:after {
  content: '';
  position: fixed; /* fixed position */
  z-index: -1; /* put it behind the content */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, hsl(300 80% 97% / 1), #e1edfb);
  transform: scale(2);
}


Body {
  font-family: system-ui, -apple-system;
}

.content-container {
  padding: 16px;
  padding-top: 88px;
  max-width: 1100px;
  margin: auto;
}

@media(min-width: 700px) {
  .content-container {
    padding: 24px;
    padding-top: 88px;
  }
}

.content-container--footer {
  padding: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  font-size: 14px;
}

@media(min-width: 700px) {
  .content-container--footer {
    padding: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.content-container--footer p {
  margin-bottom: 16px;
}

.bottom-nav-container {
  background-color: #ffffffbf;
  backdrop-filter: blur(16px) saturate(200%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color .2s ease;
}

.bottom-nav-container--dark {
  background-color: rgba(37,38,40,.21);
  backdrop-filter: blur(16px) saturate(140%);
}



.bottom-nav {

}

nav {
  max-width: 1200px;
  margin: auto;
  padding: 24px;
}

@media(max-width: 700px) {
  nav {
    padding: 16px;
  }
}

nav ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  background-color: #d4d4d466;
  border: 1px solid #72727222;
  border-radius: var(--small-radius);
  position: relative;
}

nav ul li {
  flex: 1;
  text-align: center;
}

.nav-link {
  font-size: 14px;
  display: block;
  padding: 6px;
  color: #000;
  text-decoration: none;
  border-radius: var(--small-radius)
}

.nav-link:hover {
  background-color: #ffffff6d;
}

.nav-link.active {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 8px 8px rgba(0,0,0,.15)
}

@media(min-width: 700px) {
  nav {
    max-width: 600px;
    padding: 16px;
  }
  
  .nav-link {
    padding: 8px;
  }
  
  .nav-link.active {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 16px rgba(0,0,0,.2)
  }
}

.homepage {
  position: relative;
  z-index: 10;
}

.homepage {
  min-height: 100vh;
  width: 100%;
}

.homepage:after {
  content: "";
  position: fixed; /* This could also be absolute depending on the scenario */
  top: 0;
  right: 0;
  height: 100vh;
  left: 0;
  background-image: url(/public/images/background.jpg);
  background-repeat: no-repeat;  /* Do not repeat the image */
  background-size: cover;  /* Resize the image to cover the entire container */
  background-position: center;  /* Center the background image */
  z-index: -1; /* Ensure the image stays behind your content */
  filter:opacity(.9);
}



.homepage-footer {
  background-color: white;
  width: 100%;
  color: #444;
  position: relative;
  z-index: 10;
}


.fv-pv-tabs {
  display: none;
}

.chart-and-input-container {
  margin-bottom: 140px;
  transition: all .3s;
}

@media(min-width: 1000px) {
  .chart-and-input-container {
    display: flex;
    min-height: calc(100vh - 148px);
    align-items: center;
    justify-content: space-around;
    padding-bottom: 120px;
    margin-bottom: 0;
    gap: 64px;
  }
}

.chart-container {
  padding: 16px;
  background-color: #fff;
  border-radius: var(--large-radius);
  box-shadow: 0 8px 32px rgba(0,0,0,.2);
  color:white;
  background: linear-gradient(45deg, #3b2f67, #5655bb,#a9e4f3)
}

@media(min-width: 1000px) {
  .chart-container {
    padding: 40px;
    width: 66%;
  }
}

.chart {
  height: 140px;
  @media(min-width: 700px) {
    height: 280px;
  }
}

.future-value-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  opacity: .7;
  font-size: 14px;
}

.future-value {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media(min-width: 700px) {
  .future-value-title {
    font-size: 21px;
  }
  
  .future-value {
    font-size: 42px;
    margin-bottom: 24px;
  }
}

.inputs-container {
  display: flex;
  justify-content: center;
  flex-direction: column;;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 16px;
  margin-top: 32px;
}
@media(min-width: 700px) {
  .inputs-container {
    padding: 24px;
  }
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}
@media(min-width: 1000px) {
  .inputs-container {
    padding-right: 0;
    padding-left: 40px;
  }
}

.input-field {
  padding: 0 10px;
  border: 1px solid #6c778063;
  font-size: 16px;
  width: 50%;
  border-radius: var(--small-radius);
  height: 42px;
}

@media(min-width: 700px) {
  .input-field {
    padding: 0 12px;
  }
}

.input-field:hover {
  border-color: #aaa;
}

.input-field:focus {
  border-color: #5757bc;
  box-shadow: 0 0 0 1px #5757bc;
}

.contribution-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  margin-top: 16px;
}

.contribution-container > button {
  border-radius: var(--small-radius);
  border: 1px solid #6c778063;
  padding: 10px 12px;
  gap: 8px;
  width: 100%;
}

.contribution-container > button:hover {
  border-radius: var(--small-radius);
  border: 1px solid #aaa;
  background-color: #ffffff6d;
}

.contribution-container > button.selected {
  background-color: white;
  box-shadow: 0 0 0 1px #5757bc;
  border-color: #5757bc;
}

.input-label {
  font-weight: 500;
  margin-right: 8px;
  width: 50%;
  font-size: 15px;
  height: 42px;
  display: flex;
  align-items: center;
}

@media(min-width: 700px) {
  .input-label {
    font-size: 16px;
    padding: 12px 0;
  }
}

.button-container {
  padding: 0 24px;
  width: 100%;
  display: flex;
}

.calculate-button {
  color: #2a4431;
  background-color: #d3f96d;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: 500;
  flex: 1;
  margin-top: 24px;
}

.learn-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 8px;
}

.refine-container {
  
}
@media(min-width: 900px) {
  .refine-container {
    margin-left: 24px;
  }
}

.refine-label {
  font-weight: 500;
  font-size: 13px;
  margin-right: 8px;
}

.learn-heading {
  font-size: 34px;
  font-weight: 700;
  margin-left: 0;
}

@media(min-width: 900px) {
  .learn-heading {
    margin-left: 24px;
    font-size: 42px;
  }
}



.articles-container {
  margin-bottom: 120px;
  display: flex;
  flex-wrap: wrap;
}



.article-preview-link {
  display: block;
  padding: 0;
  border: 1px solid transparent;
  border-radius: var(--large-radius);
  max-width: 100%;
}

@media(min-width: 900px) {
  .article-preview-link {
    padding: 0 24px;
    max-width: 50%;
  }
}

@media(min-width: 700px) {
  .article-preview-link:hover {
    box-shadow: 0 8px 8px 0 rgba(154, 154, 154, 0.2);
    background-color: rgba(255, 255, 255, 0.8);
  }
}


.article-preview {
  display: flex;
  gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #92929242;
}
@media(min-width: 700px) {
  .article-preview {
    gap: 16px;
  }
  }

@media(min-width: 700px) {
.article-preview-link:hover .article-preview {
  border-color: transparent;
}
}



.article-preview-title {
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 8px;
  font-weight: 700;
}

@media(max-width: 700px) {
  .article-preview-title {
    font-size: 16px;
  }
}

.article-preview-text {
  font-size: 15px;
  line-height: 1.3;
}

.article-preview-image-container {
  height: 100px;
  min-width: 100px;
  border-radius: var(--small-radius);
  overflow: hidden;
}

@media (min-width: 700px) {
  .article-preview-image-container {
    height: 120px;
    min-width: 120px;
  }
}

.article-preview-image {
  height: 100%;
  width: auto;
}

.article-container {
  padding: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 8px 16px rgba(0,0,0,.2);
  margin-bottom: 64px;
  margin-top: 32px;
  border-radius: var(--small-radius);
  overflow: hidden;
}

@media (min-width: 900px) {
  .article-container {
    border-radius: var(--large-radius);
  }
}

  .article-heading-container {
    position: relative;
    display: flex;
  }

  .article-title {
    margin-bottom: 32px;
    font-weight: 700;
    line-height: 1.2;
    font-size: 28px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 0 24px;
  }
  @media (min-width: 700px) {
    .article-title {
      font-size: 48px;
      padding: 0 120px;
    }
  }

.article-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 32px;
}
@media (min-width: 700px) {
  .article-image-container {
    padding-bottom: 50%;
  }
}
.article-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This makes sure your image covers the whole area */
}



.article-image-container:before {
    content:"";
    position: absolute;
    background:linear-gradient(0deg, #ffffff 0%, #ffffffd9, 45%, transparent 70%);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}


.paragraphs-container {
  padding: 24px;
  padding-top: 0;
}

@media (min-width: 700px) {
  .paragraphs-container {
    padding: 120px;
    padding-top: 0;
  }
}

.article-paragraph {
  margin-bottom: 24px;
  font-size: 17px;
}

@media (min-width: 900px) {
  .article-paragraph {
    font-size: 19px;
  }
}

.dd-wrapper {
  position: relative;
  display: inline-block;
}

.dd-header {
  position: relative;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #6c6c6c72;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  border-radius: var(--small-radius);
  text-align: right;
}

.dd-header:hover {
  background-color: #ffffff74;
}

.dd-header__title {
  color: rgb(0, 91, 219);
}

.dd-header__title--bold {
  font-weight: bold;
}

.dd-list {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  z-index: 10;
  padding: 0;
  margin: 2px 0 0 0;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  border-radius: var(--small-radius);
  list-style: none;
}

.dd-list-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.dd-list-item:hover {
  background-color: #f7f7f7;
}

/* Add these styles to CustomSelect.css */

.dd-header__title--unselected {
  color: #222;
}

.dd-header__title--selected {
  color: #0057d2;  /* Blue color when an option is selected */
  font-weight: bold;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-tile-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 64px;
  padding-bottom: 88px;
}
@media(min-width: 1000px) {
  .homepage-tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 32px;
  }
}

.homepage-header-section {
  grid-column: span 3; 
}

.homepage-header {
  margin-bottom: 8px;
}

.homepage-header h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}
@media(min-width: 900px) {
  .homepage-header h1 {
    font-size: 64px;
  }
}

.homepage-header p {
  font-size: 21px;
  color: #666;
}

.homepage-section {
  padding: 24px;
  background-color: #ffffffbf;
  backdrop-filter: blur(16px) saturate(200%);
  border-radius: var(--large-radius);
  overflow: hidden;
  box-shadow: 17px 20px 40px rgba(0,0,0,.21);
  transition: all .2s ease;
}
@media(min-width: 1000px) {
  .homepage-section {
    padding: 32px;
    background-color: #ffffffbf;
    backdrop-filter: blur(16px) saturate(200%);
    border-radius: var(--large-radius);
    box-shadow: 17px 20px 40px rgba(0,0,0,.21);
  }
}

.homepage-section--wide {
  grid-column: span 2; /* This will make the wide sections span two columns */
}

.homepage-section--3x-wide {
  grid-column: span 3; /* This will make the wide sections span two columns */
}

.homepage-section--future-value {
  background: linear-gradient(45deg, #3b2f67, #5655bb,#a9e4f3);
  color: white;
}

@media(min-width: 1000px) {
  .homepage-section:hover {
    box-shadow: 20px 20px 41px rgba(10,10,10,.32);
    transform: scale(1.02);
  }
}


.homepage-section-content {
  flex: 1;
  padding: 20px;
  grid-column: span 2;
}

.homepage-section .section-image {
  flex: 1;
  padding: 20px;
}

.homepage-section img {
  max-width: 100%;
  height: auto;
}

.homepage-section-title {
  font-size: 27px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 16px;
}

@media(min-width: 700px) {
  .homepage-section-title {
    font-size: 30px;
  }
}

.homepage-section p {
  font-size: 18px;
  opacity: .8;
}

.section-content--flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0070e7;
  color: white;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}

.featured-article-container {
  
  gap: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
@media(min-width: 1000px) {
  .featured-article-container {
    gap: 32px;
    margin-top: 24px;
    flex-direction: row;
  }
}

.featured-article {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
@media(min-width: 1000px) {
  .featured-article {
   margin-bottom: 0;
  }
}

.featured-article:before {
  content: "";
  position: absolute;
  left: -16px;
  right: -16px;
  bottom: -16px;
  top: -16px;
  z-index: -1;
  border-radius: 16px;

}
.featured-article:hover:before {
  background-color: white;
}

.homepage-article-image {
  border-radius: var(--small-radius);
}

.homepage-article-title {
  font-weight: 700;
  color: #000;
  text-decoration: none;
  margin-bottom: 16px;
  min-height: none;
  display: block;
  font-size: 19px;
}

@media(min-width: 1000px) {
  .homepage-article-title {
    min-height: 48px;
  }
}

.homepage-footer .footer-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.homepage-footer .footer-links a {
  text-decoration: none;
}

.homepage-footer .footer-links a:hover {
  color: #666;
}

.footer-bottom {
  background-color: #f4f4f4;
  padding: 32px;
  text-align: center;
  font-size: 15px;
}

.dropdown {
  position: absolute;
  background-color: #f6f6f6;
  min-width: 160px;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown div:hover {
  background-color: #f1f1f1;
}